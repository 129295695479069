 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="货源编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游单号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="上游单号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="组编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机号">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨结算" value="1"></el-option>
					<el-option label="按方结算" value="2"></el-option>
					<el-option label="按趟结算" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要回程" value="1"></el-option>
					<el-option label="不需回程" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="车型要求">
				<el-cascader class="el_input" v-model="form.need_truck_type" placeholder="请选择车型" :options="ser_para.truck_type" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="发货地区">
				<el-cascader class="el_input" v-model="form.case_city" placeholder="请选择车型" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="卸货地区">
				<el-cascader class="el_input" v-model="form.aim_city" placeholder="请选择车型" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="success" @click="to_cargo_add">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="货源编号/组别编号/上游单号" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">编号: {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别: {{scope.row.group1_num}}</div>
						<div class="tab_line_item">上游: {{scope.row.out_trade_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.case_prov}} / {{scope.row.case_city}} / {{scope.row.case_county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.case_addr?scope.row.case_addr:'未设置'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.aim_prov}} / {{scope.row.aim_city}} / {{scope.row.aim_county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.aim_addr?scope.row.aim_addr:'未设置'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(名称) {{scope.row.cargo_name}}</div>
						<div class="tab_line_item">(重量) {{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">(体积) {{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">(距离) {{scope.row.distance}} 公里</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">(运费) {{scope.row.freight_total_text}}</div>
						<div class="tab_line_item">(结算) {{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">(回程) {{scope.row.is_need_return_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发货时间/货车要求/备注" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">(发货){{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">(车型){{scope.row.need_truck_type_text}}</div>
						<div class="tab_line_item">(备注){{scope.row.mark?scope.row.mark:"无"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名){{scope.row.shipper_info.name}}</div>
						<div class="tab_line_item">(电话){{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">(公司){{scope.row.shipper_info.company_name}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="125">
					<template slot-scope="scope">
						<el-button @click="to_truck_cargo_info(scope.row,'info')" size="mini" type="text">详情</el-button>
						<el-button @click="to_truck_cargo_info(scope.row,'edit')" type="text" size="mini">编辑</el-button>
						<el-button @click="truck_cargo_del(scope.row)" type="text" size="mini">删除</el-button>
						<el-button @click="voucher_show(scope.row.truck_tord_num)" size="mini" type="text" class="btn_left">凭证</el-button>
						<el-button @click="tendered_list(scope.row)" type="text" size="mini">投标</el-button>
						<el-button @click="tord_truck_owner_info(scope.row)" type="text" size="mini">指定</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 承运方弹出层 -->
		<el-dialog title="指定承运方" width="450px" :visible.sync="truck_owner.dialog_is_show">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="车主电话">
					<el-input type="number" @change="truck_owner_ser_open" v-model="truck_owner.form.tel" placeholder="不填表示不指定" clearable>
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item label="车主信息">
					<el-input v-model="truck_owner.form.name" :disabled="true" clearable></el-input>
				</el-form-item>
				<div style="text-align:center">
					<el-button type="primary" @click="truck_owner_sub">指定车主</el-button>
					<el-button type="danger" @click="truck_owner_clear">撤销指定</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>

		<!-- 报价弹出层 -->
		<el-dialog title="报价列表" width="900px" top="1vh" :visible.sync="tendered.is_show">
			<el-table :data="tendered.list" :border="true" :stripe="true" size="small" height="500px" v-loading="tendered.loading">
				<el-table-column label="报价车主">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名){{scope.row.truck_owner_info.name}}</div>
						<div class="tab_line_item">(电话){{scope.row.truck_owner_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="报价金额">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.freight_tendered}}</div>
					</template>
				</el-table-column>
				<el-table-column label="报价备注">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.mark}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="125">
					<template slot-scope="scope">
						<el-button @click="tendered_choose(scope.row)" size="mini" type="text">选定</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>

	</div>
</template>

<script>
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import voucher from '../voucher/voucher_before_end.vue'
	export default {
		components: {
			voucher
		},
		data() {
			return {

				//指定车主
				truck_owner:{
					dialog_is_show:false,//模态框开启状态
					truck_tord_num:'',//货源编号(操作行)
					old_truck_owner_tel:'',//旧的被指定的车主(操作行)
					form:{
						tel:'',//车主电话
						name:'',//车主姓名
						user_num:''//车主编号
					}
				},
				
				//搜索条件参数
				ser_para:{
					cities:regionDataPlus,//省市区
					cargo_type:[],//货物类型
					truck_type:[],//货车类型
				},

				//搜索条件
				form: {				  
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					group1_num:'',//组别编号
					shipper_tel:'',//货主手机号
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					cargo_name:'',//货物名称
					need_truck_type:null,//车型要求
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//报价列表
				tendered:{
					loading:false,
					is_show:false,
					truck_tord_num:'',
					list:[],
				}
			}
		},
		created() {
			
			//获取可用搜索参数
			this.get_ser_para()

			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//投标列表
			tendered_list(item){
				this.tendered.is_show=true;
				this.tendered.truck_tord_num=item.truck_tord_num;
				this.tendered.list=[];
				this.get_tendered_list(item.truck_tord_num);
			},
			get_tendered_list(truck_tord_num){//读取报价列表

				//读取中
				this.tendered.loading=true;

				//读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_tender_list_by_admin',
						truck_tord_num:this.tendered.truck_tord_num,
					},
					callback:(data)=>{

						//读取完成
						this.tendered.loading=false;

						//置入数据
						this.tendered.list=data
					}
				});
			},
			tendered_choose(tendered_item){//选定报价
				
				//询问
				this.$my.other.confirm({
					content:"点击'确定'选择此报价",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_tender_choose_by_admin',
								truck_tord_num:this.tendered.truck_tord_num,
								tender_id:tendered_item.id
							},
							callback:(data)=>{

								//刷新数据
								this.get_page_data()
								
								//关闭弹出层
								this.tendered.is_show=false;

								//反馈数据
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
							}
						})
					}
				});
			},

			//凭证相关操作
			voucher_show(truck_tord_num){//显示凭证
				this.voucher.is_show++;
				this.voucher.truck_tord_num=truck_tord_num
			},

			//提交承运方信息
			truck_owner_sub(){
				
				//校验
				if(!this.truck_owner.form.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'没有输入车主信息,提示:输入电话号码后要点击"查找"按钮'
					});
					return;
				}
				if(this.truck_owner.form.tel==this.truck_owner.old_truck_owner_tel){
					this.truck_owner.dialog_is_show=false;
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'truck_cargo_set_truck_owner_admin',
						truck_tord_num:this.truck_owner.truck_tord_num,
						truck_owner_tel:this.truck_owner.form.tel
					},
					callback:(data)=>{

						//刷新数据
						this.get_page_data()
						
						//关闭弹出层
						this.truck_owner.dialog_is_show=false;

						//反馈数据
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
					}
				})
			},

			//撤销指定
			truck_owner_clear(){
				this.$my.other.confirm({
					content:'点击确定撤销指定此货源的承运商,撤销之后此货源会重新进入货源池',
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'truck_cargo_revoke_truck_owner_by_admin',
								truck_tord_num:this.truck_owner.truck_tord_num,
							},
							callback:(data)=>{

								//关闭弹出层
								this.truck_owner.dialog_is_show=false;

								//刷新页面
								this.get_page_data();

								//报告结果
								this.$my.other.msg({
									type:'success',
									str:'撤销成功'
								});
							}
						})
					}
				});
			},

			//电话号码输入事件,用于打开服务器搜索权限
			truck_owner_ser_open(){
				this.truck_owner.form.need_read=true;
			},

			//搜索承运方信息
			truck_owner_ser(){

				//是否输入电话号码
				if(!this.$my.check.is_tel(this.truck_owner.form.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误'
					});
					return;
				}
				
				//是否不需要读取
				if(!this.truck_owner.form.need_read){
					return;
				}

				//读取
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_owner_info',
						truck_owner_tel:this.truck_owner.form.tel,
					},
					callback:(data)=>{

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}
						
						//取出用户数据
						let user_info=this.$my.cache.get("user_info");

						//比对是否是自己
						if(user_info.user_num==data.user_num){
							this.$my.other.msg({
								type:'warning',
								str:'不能指定自己'
							});
							return;
						}

						//写入数据
						this.truck_owner.form.name=data.name
						this.truck_owner.form.user_num=data.user_num
						this.truck_owner.form.need_read=false
					}
				})
			},

			//承运方信息
			tord_truck_owner_info(item){

				//打开模态框
				this.truck_owner.dialog_is_show=true;

				//对象初始化
				this.truck_owner.truck_tord_num=item.truck_tord_num
				this.truck_owner.old_truck_owner_tel='';
				this.truck_owner.form.tel='';
				this.truck_owner.form.name=''
				this.truck_owner.form.user_num=''
				this.truck_owner.form.need_read=false;

				//置入数据
				if(item.truck_owner_info){
					this.truck_owner.old_truck_owner_tel=item.truck_owner_info.tel
					this.truck_owner.form.tel=item.truck_owner_info.tel
					this.truck_owner.form.name=item.truck_owner_info.name
					this.truck_owner.form.user_num=item.truck_owner_info.user_num
					this.truck_owner.form.need_read=false;
				}else{
					this.get_tord_truck_owner_info(item);
				}
			},

			//去服务器上读取承运方信息并缓存
			get_tord_truck_owner_info(item){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_and_truck_owner_by_cargo_num',
						truck_tord_num:item.truck_tord_num
					},
					callback:(data)=>{

						if(!data.truck_owner){
							return;
						}

						//车主数据
						this.truck_owner.old_truck_owner_tel=data.truck_owner.tel
						this.truck_owner.form.tel=data.truck_owner.tel
						this.truck_owner.form.name=data.truck_owner.name
						this.truck_owner.form.user_num=data.truck_owner.user_num
						this.truck_owner.form.need_read=false
						
						//缓存数据
						item.truck_owner_info={...data.truck_owner}
					}
				})
			},

			//删除货源
			truck_cargo_del(item){
				
				if(item.status==2){
					this.$my.other.msg({
						type:'warning',
						str:'此运单已经指定车主,不能删除'
					});
					return
				}

				//询问
				this.$my.other.confirm({
					content:"确定删除货源",
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'cargo_del_admin',
								truck_tord_num:item.truck_tord_num
							},
							callback:(data)=>{

								//刷新本页面数据
								this.get_page_data();
							}
						})
					}
				})
			},

			//前往货源详情
			to_truck_cargo_info(item,act){
				
				//跳转
				this.$router.push({
					path:'/pages/transit_real_time/cargo_edit_admin',
					query:{
						act:act,
						cargo_id:item.id,
						come_from:'/pages/transit_real_time/cargo_list_admin'
					}
				});
			},

			//前往货源添加页
			to_cargo_add(){
				this.$router.push({
					path:'/pages/transit_real_time/cargo_creat_admin'
				});
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_list_admin',
						status:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//装货时间
							if(item.case_date_start==0&&item.case_date_end!=0){//只有止点

								item.case_date=this.$my.other.todate(item.case_date_end)+"以前";
							}else if(item.case_date_start!=0&&item.case_date_end==0){//只有起点

								item.case_date=this.$my.other.todate(item.case_date_start)+"以后";
							}else if(item.case_date_start!=0&&item.case_date_end!=0){//都有

								item.case_date=this.$my.other.todate(item.case_date_start)+" ~ "+this.$my.other.todate(item.case_date_end);
							}else if(item.case_date_start==0&&item.case_date_end==0){//都没有

								item.case_date="未设置";
							}

							//卸货时间
							if(item.aim_date_start==0&&item.aim_date_end!=0){//只有止点

								item.aim_date=this.$my.other.todate(item.aim_date_end)+"以前";
							}else if(item.aim_date_start!=0&&item.aim_date_end==0){//只有起点

								item.aim_date=this.$my.other.todate(item.aim_date_start)+"以后";
							}else if(item.aim_date_start!=0&&item.aim_date_end!=0){//都有

								item.aim_date=this.$my.other.todate(item.aim_date_start)+" ~ "+this.$my.other.todate(item.aim_date_end);
							}else if(item.aim_date_start==0&&item.aim_date_end==0){//都没有
								
								item.aim_date="未设置";
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//运费
							if(item.freight_total>0){
								item.freight_total_text=item.freight_total+" 元";
							}else item.freight_total_text="未设置";

							//货车类型
							if(item.need_truck_type){
								item.need_truck_type_text=item.need_truck_type+' / '+item.need_truck_type2
							}else item.need_truck_type_text="无要求"
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//获取可用搜索参数
			get_ser_para(){

				//尝试从缓存中取出
				let ser_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(ser_para){
					this.ser_para.cargo_type=[...ser_para.cargo_type]
					this.ser_para.truck_type=[...ser_para.truck_type]
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{

						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.ser_para.cargo_type=[...data.cargo_type]
						this.ser_para.truck_type=[...truck_type]
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
</style>